<template>
  <div class="homepage">
    <div class="search-box">
      <el-form inline size="small">
        <el-form-item label="日期">
          <el-date-picker
            v-model="date"
            type="daterange"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-radio-group v-model="radio" @change="setDate">
            <el-radio-button label="近一周"></el-radio-button>
            <el-radio-button label="近一月"></el-radio-button>
            <el-radio-button label="近一年"></el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="float: right">
          <el-button type="primary" style="width: 80px" @click="handleSearch"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="card-container">
      <div class="chart-title" style="padding: 0; margin-bottom: 20px">
        补能数据
      </div>
      <div class="card-content">
        <Card
          v-for="item in cardList"
          :key="item.sub"
          :title="item.title"
          :sub="item.sub"
          :url="item.url"
        >
        </Card>
      </div>
    </div>
    <div id="trend-container" style="height: 400px">
      <div class="chart-title">充电统计</div>
      <div id="trend"></div>
    </div>
    <div id="charging-container" style="height: 400px; display: flex">
      <div id="table-container">
        <div class="chart-title">车辆补能记录</div>
        <el-table :data="tableData" style="width: 100%" max-height="350px">
          <!--<el-table-column prop="plate" label="车牌号"></el-table-column>
          <el-table-column prop="driverName" label="司机名称"></el-table-column>-->
          <el-table-column
            prop="rechargeBegin"
            label="开始充电时间"
          ></el-table-column>
          <el-table-column
            prop="rechargeEnd"
            label="结束充电时间"
          ></el-table-column>
          <el-table-column
            prop="durationMinute"
            label="充电时长(分钟)"
          ></el-table-column>
          <el-table-column prop="kwh" label="充电量（度）"></el-table-column>
          <el-table-column
            prop="kwhAmount"
            label="充电金额（元）"
          ></el-table-column>
          <el-table-column
            prop="totalAmount"
            label="充电服务金额（元）"
          ></el-table-column>
        </el-table>
        <div class="paginations" v-show="total > 0">
          <el-pagination
            :page-size="conditions.limit"
            layout="total,prev,pager,next,jumper"
            :total="total"
            :current-page="conditions.page"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
      <div id="pie-container">
        <div class="chart-title">当前补能设备情况</div>
        <div id="pie"></div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import dayjs from "dayjs";
import Card from "./components/card.vue";
export default {
  data() {
    return {
      date: [
        new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
        new Date(),
      ],
      conditions: {
        page: 1,
        limit: 5,
      },
      total: 0,
      tableData: [],
      cardList: [
        {
          title: "123,456",
          sub: "充电次数(车次)",
          url: require("../../assets/imgs/charging/pic_cdcs.png"),
        },
        {
          title: "123,456",
          sub: "充电电量(度)",
          url: require("../../assets/imgs/charging/pic_cddl.png"),
        },
        {
          title: "123,456",
          sub: "充电全额(元)",
          url: require("../../assets/imgs/charging/pic_cdqe.png"),
        },
        {
          title: "123,456",
          sub: "充电时长(小时)",
          url: require("../../assets/imgs/charging/pic_cdsc.png"),
        },
      ],
      radio: "近一周",
      trendOption: {
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: "line",
            smooth: true,
            lineStyle: {
              color: "#4FD1C5",
              width: 2,
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "rgb(255, 255, 255)",
                },
                {
                  offset: 0,
                  color: "rgb(79,209,197)",
                },
              ]),
            },
          },
        ],
      },
      pieOption: {
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "5%",
          left: "center",
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 1048, name: "运行中" },
              { value: 735, name: "空闲" },
            ],
          },
        ],
      },
    };
  },
  components: {
    Card,
  },
  created() {
    this.handleSearch();
  },
  mounted() {
    this.initCharts();
  },
  methods: {
    setDate() {
      if (this.radio == "近一周") {
        this.date = [
          new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
          new Date(),
        ];
      }
      if (this.radio == "近一月") {
        this.date = [
          new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
          new Date(),
        ];
      }
      if (this.radio == "近一年") {
        this.date = [
          new Date(new Date().getTime() - 365 * 24 * 60 * 60 * 1000),
          new Date(),
        ];
      }
    },
    getCondition() {
      let that = this;
      return {
        begin: that.date[0] ? new dayjs(that.date[0]).format("YYYY-MM-DD") : "",
        end: that.date[1] ? new dayjs(that.date[1]).format("YYYY-MM-DD") : "",
      };
    },
    async getFleetOptions() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/FleetInfo/ownList",
        "get",
        {}
      );
      this.fleetOptions = data.data;
      if (this.fleetOptions.length > 0) {
        this.conditions.fleetId = this.fleetOptions[0].id;
        this.getList();
      }
    },
    initCharts() {
      var trendDom = document.getElementById("trend");
      var trendChart = echarts.init(trendDom);
      this.trendOption && trendChart.setOption(this.trendOption);
      var pieDom = document.getElementById("pie");
      var pieChart = echarts.init(pieDom);
      this.pieOption && pieChart.setOption(this.pieOption);
    },
    handleSearch() {
      this.getCards();
      this.getTrendChart();
      this.getTable();
      this.getPie();
    },
    async getCards() {
      const { data } = await this.$http(
        "api1",
        "/api/asset/RechargeRecord/summary",
        "get",
        this.getCondition()
      );
      console.log(data);
      this.cardList[0].title = data.data.chargeCount;
      this.cardList[1].title = data.data.kwh;
      this.cardList[2].title = data.data.kwhAmount;
      this.cardList[3].title = data.data.chargeHour;
    },
    handleCurrentChange(newPage) {
      this.conditions.page = newPage;
      this.getTable();
    },
    async getTable() {
      const { data } = await this.$http(
        "api1",
        "/api/asset/RechargeRecord/list",
        "get",
        {
          ...this.getCondition(),
          ...this.conditions,
        }
      );
      this.tableData = data.data.list;
      this.total = data.data.totalCount;
    },
    async getPie() {
      const { data } = await this.$http(
        "api1",
        "/api/asset/ChargePort/statusStat",
        "get",
        this.getCondition()
      );
      this.pieOption.series[0].data = [
        { value: data.data.chargeCount, name: "运行中" },
        { value: data.data.freeCount, name: "空闲" },
      ];
      console.log(data);
    },
    async getTrendChart() {
      const res = await this.$http(
        "api1",
        "/api/asset/RechargeRecord/chargeCountStat",
        "get",
        this.getCondition()
      );
      this.trendOption.xAxis.data = res.data.data.map((item) => item.dateStr);
      this.trendOption.series[0].data = res.data.data.map(
        (item) => item.chargeCount
      );
      console.log(this.trendOption);
      this.$nextTick(() => {
        this.initCharts();
      });
    },
    handleSelectionChange(val) {
      this.checkedIds = val.map((item) => item.id);
    },
  },
};
</script>
<style lang="scss" scoped>
.main_container_box {
  height: 100%;
}

.homepage {
  height: 100%;
}
.search-box {
  padding-top: 20px;
  padding-left: 20px;
  background-color: #fff;
  border-radius: 10px;
}
.card-container {
  margin: 20px 0;
  background-color: #fff;

  padding: 20px;
}
.card-content {
  height: 210px;
  display: flex;
  * {
    flex: 1;
    margin: 0 15px;
  }
}
.homepage_content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.icon {
  width: 68px;
  height: 68px;
  margin-right: 16px;
}
.chart-title {
  font-size: 20px;
  color: #202647;
  font-style: italic;
  font-weight: bold;
  padding: 20px;
}

#trend-container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}
#trend {
  flex: 1;
}
#charging-container {
  margin-top: 20px;
}
#table-container {
  flex: 3;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

#pie-container {
  margin-left: 25px;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
}
#pie {
  flex: 1;
}
.radio-container {
  padding-left: 20px;
}
</style>
