<template>
  <div class="card">
    <el-image class="img" :src="url"></el-image>
    <div class="text">
      <div class="sub">{{ sub }}</div>
      <div class="title">{{ title }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    url: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    sub: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.card {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: transparent;
  //box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  &:nth-child(odd) {
    color: #115b76;
  }
  &:nth-child(even) {
    color: #2f419b;
  }
}
.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.text {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  z-index: 1;
  width: 100%;
  margin-bottom: 50px;
  vertical-align: bottom;
  line-height: 1;
}
.title {
  font-weight: 500;
  font-size: 40px;
}
.sub {
  font-weight: bold;
  font-size: 17px;
}
</style>
